// Sales Widget
import { Bar, mixins } from "vue-chartjs"
const { reactiveProp } = mixins
export default {
    extends: Bar,
    mixins: [reactiveProp],
    data: function() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label
                            var value = tooltipItem.value
                            value = value.toString()
                            value = value.split(/(?=(?:...)*$)/)
                            value = value.join(",")
                            return [label + ": " + value]
                        }
                    }
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(value) {
                                    // Convert the number to a string and splite the string every 3 charaters from the end
                                    value = value.toString()
                                    value = value.split(/(?=(?:...)*$)/)
                                    // Convert the array to a string and format the output
                                    value = value.join(",")
                                    return value
                                }
                            },
                            gridLines: {
                                display: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            categoryPercentage: 0.5,
                            barPercentage: 0.8
                        }
                    ]
                },
                legend: {
                    onHover: function(e) {
                        e.target.style.cursor = "pointer"
                    },
                    align: "center",
                    display: true
                },
                hover: {
                    onHover: function(e) {
                        var point = this.getElementAtEvent(e)
                        if (point.length) e.target.style.cursor = "pointer"
                        else e.target.style.cursor = "default"
                    }
                }
            }
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
        // this.renderChart({
        //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        //     datasets: [
        //         {
        //             type: 'bar',
        //             label: 'Earned',
        //             labels: 'Earned',
        //             backgroundColor: ChartConfig.color.primary,
        //             hoverBackgroundColor: ChartConfig.color.primary,
        //             borderWidth: 0,
        //             data: [190000, 320000, 50000, 420000, 32000, 460000, 300000, 250000, 290000, 210000, 610000, 250000]
        //         },
        //     ]
        // }, this.options)
    }
}
